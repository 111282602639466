<script setup>
    import { defineProps } from 'vue'

    const props = defineProps({
      Code: String,
      Title: String,
      Type: String,
      Date: String,
      Time: String,
      Location: String,
      ZoomLink: String,
      SessionLink: { type: String, default() { return "" }},
      Wordly: { type: String, default() { return "" }},
      ZoomLinkTitle: { type: String, default() { return "" }}
    })

    let style=""

    switch (props.Type) {
      case "Special session": style="background-color:rgb(244,73,39);color:rgb(255,255,255);"; break;
      case "Focused symposium": style="background-color:rgb(0,76,255);color:rgb(255,255,255);"; break;
      case "Seminar": style="background-color:rgb(128,170,255);color:rgb(0,0,0);"; break;
      case "World Physiotherapy seminar": style="background-color:rgb(191,210,255);color:rgb(0,0,0);"; break;
      case "Discussion": style="background-color:rgb(0,178,169);color:rgb(255,255,255);"; break;
    }

    let zoomlinktitle = props.ZoomLinkTitle ? props.ZoomLinkTitle : "Connect to Zoom"


</script>

<template>
  <div class="col">
      <div class="card wp-card shadow wp-session bg-white">
          <div class="card-header d-flex flex-wrap justify-content-between" :style="style">
              <div class="session-code">{{ props.Code }}</div>
              <div class="session-type">{{ props.Type }}</div>
          </div>
          <div class="session-programming d-flex flex-wrap justify-content-between">
                <div class="session-date"><i class="bi bi-calendar-event pe-1 fg-wp-dark"></i>{{ props.Date }}</div>
                <div class="session-time"><i class="bi bi-clock pe-1 fg-wp-dark"></i>{{ props.Time }}</div>
                <div class="session-room"><i class="bi bi-geo-alt pe-1 fg-wp-dark"></i>{{ props.Location }}</div>
          </div>
          <div class="card-body bg-wp-light">

              <h6 class="card-title">{{ props.Title }}</h6>

              <div class="mt-3">
                <a :href="props.ZoomLink" target="_blank" class="btn btn-sm btn-primary me-3 zoom-button">{{ zoomlinktitle }}</a>
                <a v-if="Wordly" :href="props.Wordly" target="_blank" class="btn btn-sm btn-outline-primary me-3 zoom-button">Wordly translation</a>
                <a v-if="SessionLink" :href="props.SessionLink" class="btn btn-sm btn-outline-primary">Original session</a>
                <!--<a v-if="props.mysessions && !AbstractType && !confirmed" href="#" class="btn btn-sm btn-primary ms-2">Confirm intention to present</a>-->  <!-- Link to planner confirmation -->
                <!--<a v-if="props.mysessions && !AbstractType && confirmed" href="#" class="btn btn-sm btn-primary ms-2">Update confirmation</a>-->  <!-- Link to planner confirmation -->
              </div>
          </div>
      </div>
  </div>
</template>
